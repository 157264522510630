@media only screen and (min-width: 900px) {
  .card-image {
    display: block;
    width: 13rem !important;
    height: 13rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .card-image {
    width: 13rem !important;
    height: 13rem !important;
    border: 0.5px solid black;
  }
}

/* img {
    width: 13rem !important;
    height: 13rem !important;
    border: 0.5px solid black;
  } */
