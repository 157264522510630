@media only screen and (min-width: 900px)  {
    .resized-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 8rem;
        height: 8rem;
    }
}

@media only screen and (max-width: 900px)  {
    .resized-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 5rem;
        height: 5rem;
    }
}
