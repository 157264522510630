@tailwind base;
@tailwind components;
@tailwind utilities;
html, body
{
    padding: 0;
    margin: 0;
    height: 100%;
}
body {
  margin: 0;
  font-family: 'Courier New' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  padding: 1rem;
}