.residencies {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .block-picker {
        width: 100% !important;
    }

    .btnCancel {
        margin-top: 7px;
    }
  }